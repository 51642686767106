import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import ChevronDown from '../../Icon/ChevronDown'
import Portal from '../Portal'
import * as styles from './multiSelectBox.module.scss'
import Checkbox from '../Checkbox'

export interface MultiSelectBoxOption {
  label: string | ReactNode
  value: string
  selected?: boolean
}

interface MultiSelectBoxProps {
  label: string | ReactNode
  identifier: string
  options: MultiSelectBoxOption[]
  onChange?(data: MultiSelectBoxOption[]): void
  dropDownClassName?: string
  value?: string
}

export default function MultiSelectBox(props: MultiSelectBoxProps) {
  const {
    label,
    onChange,
    options = [],
    dropDownClassName = '',
    identifier,
    value,
  } = props
  const multiSelectBoxRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const labelRef = useRef<HTMLSpanElement>(null)

  const [currentSelects, setCurrentSelects] = useState<MultiSelectBoxOption[]>(
    []
  )

  const closeMenu = () => {
    const $dropdown = document.querySelectorAll(`.${styles.dropdown}`)
    Array.from($dropdown).forEach((s) => {
      s.classList.remove(styles.multiSelectBoxActive)
    })
  }

  const handleOpenDropdown = (e: React.MouseEvent) => {
    closeMenu()
    e.preventDefault()
    if (dropdownRef.current && multiSelectBoxRef.current) {
      const axis = multiSelectBoxRef.current.getBoundingClientRect()
      if (labelRef.current) {
        dropdownRef.current.style.top = `${
          axis.top + labelRef.current.offsetHeight + window.scrollY
        }px`
        dropdownRef.current.style.left = `${axis.left}px`
      }
      dropdownRef.current.classList.add(styles.multiSelectBoxActive)
    }
  }

  const handleChange = (name: string, checkedState: boolean) => {
    const checkedItem = options.find((o) => o.value === name)

    const newCurrentSelects = checkedState
      ? [
          ...currentSelects,
          {
            label: checkedItem?.label || '',
            value: checkedItem?.value || '',
            selected: checkedState,
          },
        ]
      : currentSelects.filter((s) => s.value !== name)

    setCurrentSelects(newCurrentSelects)
    onChange && onChange(newCurrentSelects)
  }

  useEffect(() => {
    window.addEventListener('mousedown', (e: MouseEvent) => {
      if (!(e.target as HTMLElement).closest(`.${styles.dropdown}`)) {
        closeMenu()
      }
    })
  }, [])

  // useMemo(() => {
  //   const selected = options.find((o) => o.selected)
  //   setCurrentSelects(selected ? [selected.label] : [label])
  // }, [options])

  // useMemo(() => {
  //   const findCurrent = options.find((o) => o.value === value)
  //   setCurrentSelects(findCurrent ? [findCurrent.label] : [options[0].label])
  // }, [value])

  return (
    <div className={styles.multiSelectBox} ref={multiSelectBoxRef}>
      <span
        className={styles.multiSelectBoxLabel}
        ref={labelRef}
        onClick={handleOpenDropdown}
      >
        {label}
        <ChevronDown />
      </span>
      <Portal>
        <div
          className={`${styles.dropdown} ${dropDownClassName}`}
          ref={dropdownRef}
        >
          {options.map((o, index) => (
            <Checkbox
              id={`cb-${identifier}-${index}`}
              key={`cb-${identifier}-${index}`}
              name={o.value}
              onChange={handleChange}
            >
              {o.label}
            </Checkbox>
          ))}
        </div>
      </Portal>
    </div>
  )
}
